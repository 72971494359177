export const categoryOptions = [
  {
    label: '分类图片',
    prop: 'icon_url',
    sortable: false,
    width: 200
  },
  {
    label: '分类名称',
    prop: 'name',
    sortable: false
  },
  {
    label: '操作',
    prop: 'actions',
    sortable: false,
    width: 150
  }
]
