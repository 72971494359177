import router from './index'
import store from '@/store'

const whiteList = ['/login', '/loginByToken']
router.beforeEach((to, from, next) => {
  if (whiteList.includes(to.path)) {
    next()
  } else {
    if (store.getters.token) {
      if (to.path === '/login') {
        next('/')
      } else {
        next()
      }
    } else {
      next('/login')
    }
  }
})
