import request from './request'

export const login = (data) => {
  return request({
    url: '/Applet/LoginWeb',
    method: 'POST',
    data
  })
}

export const LoginWebByWinToken = (params) => {
  return request({
    url: '/Applet/LoginWebByWinToken',
    method: 'GET',
    params
  })
}
