import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  setup: function setup(__props) {
    var store = useStore();

    var toggleClick = function toggleClick() {
      store.commit('app/changeSiderType');
    };

    var icon = computed(function () {
      return store.getters.siderType ? 'hamburger-opened' : 'hamburger-closed';
    });
    return function (_ctx, _cache) {
      var _component_svg_icon = _resolveComponent("svg-icon");

      return _openBlock(), _createElementBlock("div", {
        class: "hamburger-container",
        onClick: toggleClick,
        id: "hamburger"
      }, [_createVNode(_component_svg_icon, {
        icon: _unref(icon)
      }, null, 8, ["icon"])]);
    };
  }
};