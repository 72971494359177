import request from './request'

export const showset = (params) => {
  return request({
    url: '/AppletMallSets/showset',
    method: 'GET',
    params
  })
}
export const showsetStore = (data) => {
  return request({
    url: '/AppletMallSets/showsetStore',
    method: 'POST',
    data
  })
}
