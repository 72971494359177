import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0f412dfc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "navbar"
};
var _hoisted_2 = {
  class: "navbar-right"
};
import Hamburger from './components/hamburger.vue';
import Breadcrumb from './components/breadcrumb.vue';
import Avatar from './components/avatar.vue';
import Lang from './components/lang.vue';
import ScreenFull from './components/screenFull.vue';
import Driver from './components/driver';
export default {
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(Hamburger), _createVNode(Breadcrumb), _createElementVNode("div", _hoisted_2, [_createVNode(_unref(Driver), {
        class: "navbar-item"
      }), _createVNode(ScreenFull, {
        class: "navbar-item"
      }), _createVNode(Lang, {
        class: "navbar-item"
      }), _createVNode(Avatar, {
        class: "navbar-item"
      })])]);
    };
  }
};