export const options = [
  {
    label: '分类名称',
    prop: 'name',
    sortable: false,
    width: 200
  },
  {
    label: '排序',
    prop: 'sort_order',
    sortable: false,
    width: 100
  },
  {
    label: '产品数量',
    prop: 'goods_count',
    sortable: false,
    width: 100
  },
  {
    label: '产品',
    prop: 'goods_list',
    sortable: false
  },
  {
    label: '图标显示',
    prop: 'is_channel',
    sortable: false,
    width: 100
  },
  {
    label: '首页显示',
    prop: 'is_show',
    sortable: false,
    width: 100
  },
  {
    label: '全部产品页面显示',
    prop: 'is_category',
    sortable: false,
    width: 100
  },
  {
    label: '操作',
    prop: 'actions',
    sortable: false,
    width: 150
  }
]
