import request from './request'
export const adList = (params) => {
  return request({
    url: '/AppletMall/adList',
    method: 'GET',
    params
  })
}

export const updateAdSort = (params) => {
  return request({
    url: '/AppletMall/updateAdSort',
    method: 'GET',
    params
  })
}
export const enableStatus = (params) => {
  console.log(params)
  return request({
    url: '/AppletMall/adEnabled',
    method: 'GET',
    params
  })
}
export const editAdImage = (params) => {
  console.log(params)
  return request({
    url: '/AppletMall/editAdImage',
    method: 'GET',
    params
  })
}
export const editAdGoods = (params) => {
  console.log(params)
  return request({
    url: '/AppletMall/editAdGoods',
    method: 'GET',
    params
  })
}
export const updateAdEndTime = (params) => {
  console.log(params)
  return request({
    url: '/AppletMall/updateAdEndTime',
    method: 'GET',
    params
  })
}
