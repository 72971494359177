export const options = [
  {
    label: '资讯图片',
    prop: 'pic_url',
    sortable: false,
    width: 150
  },
  {
    label: '资讯主题',
    prop: 'title',
    sortable: false
  },
  {
    label: '分类',
    prop: 'news_cates',
    sortable: false
  },
  {
    label: '发布',
    prop: 'is_on_show',
    sortable: false
  },
  {
    label: '简介',
    prop: 'news_brief',
    sortable: false
  },
  {
    label: '浏览量',
    prop: 'view_count',
    sortable: true,
    width: 100
  },
  {
    label: '点赞量',
    prop: 'view_count',
    sortable: true,
    width: 100
  },
  {
    label: '操作',
    prop: 'actions',
    sortable: false,
    width: 150
  }
]
