import request from './request.js'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

export const generatePresignedUrlApi = (params) => {
  return request({
    url: '/AppletMall/getOssToken',
    method: 'get',
    params: params
  })
}

export const upload = async (
  file,
  successCallback,
  errCallBack,
  progressCallback,
  dir = 'image'
) => {
  const fileName = file.name
  const generate = await generatePresignedUrlApi({
    dir: dir // 'image'   // 这里的参数，对应的就是上传到那个文件夹下面，找后台要
  })
  if (generate.Success) {
    // 拿到签名信息后，组装表单数据，作参考，具体的字段找后台要
    const obj = JSON.parse(generate.token)
    const config = {}
    config.host = obj.host
    config.policyBase64 = obj.policy
    config.accessid = obj.accessid
    config.signature = obj.signature
    config.expire = parseInt(obj.expire)
    config.callbackbody = obj.callback
    config.dir = obj.dir
    const fd = new FormData()
    const uuid = uuidv4()
    const key = config.dir + uuid
    fd.append('key', key)
    fd.append('success_action_status', '200')
    fd.append('x-oss-object-acl', 'public-read')
    fd.append('x-oss-meta-fullname', fileName)
    fd.append('OSSAccessKeyId', config.accessid)
    fd.append('policy', config.policyBase64)
    fd.append('signature', config.signature)
    fd.append('success_action_status', '200')
    fd.append('file', file)
    if (config.host.indexOf('http:') > -1) {
      const protocol = window.location.protocol || 'http:'
      const subUrl = config.host.substring(5, config.host.length)
      config.host = protocol + subUrl
    } else if (config.host.indexOf('http:') === -1) {
      const protocol = 'https://'
      config.host = protocol + config.host
    }
    // 数据组装完成后，发送上传请求到阿里云oss
    axios({
      url: config.host,
      method: 'POST',
      data: fd,
      processData: false,
      cache: false,
      contentType: false,
      // 这里，我们可以做上传经度
      onUploadProgress: function (progressEvent) {
        if (progressEvent.lengthComputable) {
          const percent =
            (progressEvent.loaded / progressEvent.total) * 100 || 0
          progressCallback({
            percent: percent
          })
        }
      }
    })
      .then((res) => {
        // 拿到结果后，做其他操作
        const size =
          file.size > 1000000
            ? parseFloat(file.size / 1000000).toFixed(2) + 'M'
            : parseFloat(file.size / 1000).toFixed(2) + 'KB'
        successCallback({
          attachment: fileName,
          aliyunAddress: key,
          size: size,
          host: config.host
        })
      })
      .catch((err) => {
        errCallBack(err)
      })
  }
}
