import request from './request'

export const categoryList = (params) => {
  return request({
    url: '/AppletMallGoods/categoryList',
    method: 'GET',
    params
  })
}
export const channelStatus = (params) => {
  console.log(params)
  return request({
    url: '/AppletMallGoods/categoryChannelStatus',
    method: 'GET',
    params
  })
}
export const showStatus = (params) => {
  console.log(params)
  return request({
    url: '/AppletMallGoods/categoryShowStatus',
    method: 'GET',
    params
  })
}
export const categoryStatus = (params) => {
  console.log(params)
  return request({
    url: '/AppletMallGoods/categoryStatus',
    method: 'GET',
    params
  })
}
export const updateCategtorySort = (params) => {
  return request({
    url: '/AppletMallGoods/updateCategtorySort',
    method: 'GET',
    params
  })
}
export const getCategoryInfo = (params) => {
  return request({
    url: '/AppletMallGoods/getCategoryInfo',
    method: 'GET',
    params
  })
}
export const storeCategory = (data) => {
  return request({
    url: '/AppletMallGoods/storeCategory',
    method: 'POST',
    data
  })
}
export const deleteCategory = (params) => {
  return request({
    url: '/AppletMallGoods/deleteCategory',
    method: 'GET',
    params
  })
}
