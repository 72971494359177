import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5a0a1fde"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 0,
  class: "no-redirect"
};
var _hoisted_2 = ["onClick"];
import { watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  setup: function setup(__props) {
    var route = useRoute();
    var router = useRouter();
    var breadcrumbList = ref([]);

    var initBreadcrumbList = function initBreadcrumbList() {
      breadcrumbList.value = route.matched;
    };

    var handleRedirect = function handleRedirect(path) {
      router.push(path);
    };

    watch(route, function () {
      initBreadcrumbList();
    }, {
      deep: true,
      immediate: true
    });
    return function (_ctx, _cache) {
      var _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

      var _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

      return _openBlock(), _createBlock(_component_el_breadcrumb, {
        separator: "/"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumbList.value, function (item, index) {
            return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
              key: index
            }, {
              default: _withCtx(function () {
                return [index === breadcrumbList.value.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("menus.".concat(item.name))), 1)) : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: "redirect",
                  onClick: function onClick($event) {
                    return handleRedirect(item.path);
                  }
                }, _toDisplayString(_ctx.$t("menus.".concat(item.name))), 9, _hoisted_2))];
              }),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      });
    };
  }
};