import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import Menu from './Menu';
import Headers from './headers';
import { computed } from 'vue';
import variables from '@/styles/variables.scss';
import { useStore } from 'vuex';
export default {
  setup: function setup(__props) {
    var store = useStore(); // const asideWidth = ref(variables.sideBarWidth)

    var asideWidth = computed(function () {
      return store.getters.siderType ? variables.sideBarWidth : variables.hideSideBarWidth;
    });
    return function (_ctx, _cache) {
      var _component_el_aside = _resolveComponent("el-aside");

      var _component_el_header = _resolveComponent("el-header");

      var _component_router_view = _resolveComponent("router-view");

      var _component_el_main = _resolveComponent("el-main");

      var _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createBlock(_component_el_container, {
        class: "app-wrapper"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_aside, {
            width: _unref(asideWidth),
            class: "sidebar-container"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_unref(Menu))];
            }),
            _: 1
          }, 8, ["width"]), _createVNode(_component_el_container, {
            class: _normalizeClass(["container", {
              hidderContainer: !_ctx.$store.getters.siderType
            }])
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_header, null, {
                default: _withCtx(function () {
                  return [_createVNode(_unref(Headers))];
                }),
                _: 1
              }), _createVNode(_component_el_main, null, {
                default: _withCtx(function () {
                  return [_createVNode(_component_router_view)];
                }),
                _: 1
              })];
            }),
            _: 1
          }, 8, ["class"])];
        }),
        _: 1
      });
    };
  }
};