import request from './request'

export const addImageCategory = (data) => {
  return request({
    url: '/AppletMallImage/addImageCategory',
    method: 'POST',
    data
  })
}
export const deleteImageCategory = (params) => {
  return request({
    url: '/AppletMallImage/deleteImageCategory',
    method: 'GET',
    params
  })
}
export const getImageCategoryList = () => {
  return request({
    url: '/AppletMallImage/getImageCategoryList',
    method: 'GET'
  })
}
export const getImageList = (params) => {
  return request({
    url: '/AppletMallImage/getImageList',
    method: 'GET',
    params
  })
}
export const addImage = (data) => {
  return request({
    url: '/AppletMallImage/addImage',
    method: 'POST',
    data
  })
}
export const deleteImage = (params) => {
  return request({
    url: '/AppletMallImage/deleteImage',
    method: 'get',
    params
  })
}
export const moveImage = (params) => {
  return request({
    url: '/AppletMallImage/moveImage',
    method: 'get',
    params
  })
}
