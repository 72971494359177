import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import screenfull from 'screenfull';
import { ref, onMounted, onBeforeMount } from 'vue';
export default {
  setup: function setup(__props) {
    var icon = ref(screenfull.isFullscreen);

    var handleFullScreen = function handleFullScreen() {
      if (screenfull.isEnabled) {
        screenfull.toggle();
      }
    };

    var changeIcon = function changeIcon() {
      icon.value = screenfull.isFullscreen;
    };

    onMounted(function () {
      screenfull.on('change', changeIcon);
    });
    onBeforeMount(function () {
      screenfull.off('change');
    });
    return function (_ctx, _cache) {
      var _component_svg_icon = _resolveComponent("svg-icon");

      return _openBlock(), _createElementBlock("div", {
        onClick: handleFullScreen,
        id: "screenFul"
      }, [_createVNode(_component_svg_icon, {
        icon: icon.value ? 'exit-fullscreen' : 'fullscreen'
      }, null, 8, ["icon"])]);
    };
  }
};