export const adOptions = [
  {
    label: '广告',
    prop: 'image_url',
    sortable: false,
    width: 200
  },
  {
    label: '关联商品',
    prop: 'goods_name',
    sortable: false
  },
  {
    label: '结束时间',
    prop: 'end_time',
    sortable: false
  },
  {
    label: '排序',
    prop: 'sort_order',
    sortable: false,
    width: 100
  },
  {
    label: '开启状态',
    prop: 'enabled',
    sortable: false,
    width: 100
  },
  {
    label: '操作',
    prop: 'actions',
    sortable: false,
    width: 100
  }
]
