import request from './request'

export const techList = (params) => {
  return request({
    url: '/SupportTopic/Select',
    method: 'GET',
    params
  })
}
export const AddTech = (data) => {
  return request({
    url: '/SupportTopic/Add',
    method: 'POST',
    data: data
  })
}
export const EditTech = (data) => {
  return request({
    url: '/SupportTopic/Edit',
    method: 'POST',
    data: data
  })
}
export const DeleteTech = (params) => {
  return request({
    url: '/SupportTopic/Delete',
    method: 'GET',
    params
  })
}

export const updateSort = (params) => {
  return request({
    url: '/AppletMallTech/updateTechSort',
    method: 'GET',
    params
  })
}
export const indexShowStatus = (params) => {
  console.log(params)
  return request({
    url: '/AppletMallTech/indexShowStatus',
    method: 'GET',
    params
  })
}
export const showStatus = (params) => {
  return request({
    url: '/AppletMallTech/ShowStatus',
    method: 'GET',
    params
  })
}
export const getTechInfo = (params) => {
  return request({
    url: '/SupportTopic/Single',
    method: 'GET',
    params
  })
}
export const GetSectionTreeList = (params) => {
  return request({
    url: '/SupportSection/GetSectionTreeList',
    method: 'GET',
    params
  })
}
export const storeTechTips = (params) => {
  return request({
    url: '/SupportTopic/storeTechTips',
    method: 'GET',
    params
  })
}
export const deleteTechTips = (params) => {
  return request({
    url: '/SupportTopic/deleteTechTips',
    method: 'GET',
    params
  })
}
export const getImageList = (params) => {
  return request({
    url: '/SupportTopic/getImageList',
    method: 'GET',
    params
  })
}

export const addImage = (params) => {
  return request({
    url: '/SupportTopic/addImage',
    method: 'GET',
    params
  })
}
export const addSupportTopicImage = (params) => {
  return request({
    url: '/SupportTopic/addSupportTopicImage',
    method: 'GET',
    params
  })
}
export const deleteSupportTopicImage = (params) => {
  return request({
    url: '/SupportTopic/deleteSupportTopicImage',
    method: 'GET',
    params
  })
}

export const getSupportSectionList = (params) => {
  return request({
    url: '/SupportSection/GetSectionTreeList',
    method: 'GET',
    params
  })
}
export const storeSection = (data) => {
  return request({
    url: '/SupportSection/Add',
    method: 'POST',
    data
  })
}
export const editSection = (data) => {
  return request({
    url: '/SupportSection/Edit',
    method: 'POST',
    data
  })
}
export const deleteSection = (params) => {
  return request({
    url: '/SupportSection/Delete',
    method: 'GET',
    params
  })
}
