export const options = [
  {
    label: '分类名称',
    prop: 'Name',
    sortable: false
  },
  {
    label: '操作',
    prop: 'actions',
    sortable: false,
    width: 150
  }
]
