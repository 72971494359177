export const options = [
  {
    label: '商品图片',
    prop: 'https_pic_url',
    sortable: false,
    width: 150
  },
  {
    label: '商品名称',
    prop: 'name',
    sortable: false
  },

  {
    label: '选择分类',
    prop: 'editCategory',
    sortable: false
  },
  {
    label: '销量',
    prop: 'sell_volume',
    sortable: true,
    width: 100
  },
  {
    label: '库存',
    prop: 'goods_number',
    sortable: true,
    width: 100
  },
  {
    label: '价格',
    prop: 'retail_price',
    sortable: false,
    width: 100
  },
  {
    label: '首页显示',
    prop: 'is_index',
    sortable: false,
    width: 100
  },
  {
    label: '上架',
    prop: 'is_on_sale',
    sortable: false,
    width: 100
  },
  {
    label: '操作',
    prop: 'actions',
    sortable: false,
    width: 150
  }
]
