import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
import { ref } from 'vue';
import variables from '@/styles/variables.scss';
export default {
  setup: function setup(__props) {
    var iconList = ref(['user', 'setting', 'shop', 'tickets', 'pie-chart']);
    var icon = ref('menu');
    var defaultActive = ref(sessionStorage.getItem('path') || '/categories');
    var menusList = ref([{
      authName: '后台主页',
      id: 1,
      children: [{
        authName: '后台主页',
        order: 1,
        path: 'home'
      }]
    }, {
      authName: '商品管理',
      id: 2,
      children: [{
        authName: '商品列表',
        children: [],
        order: 1,
        path: 'goods'
      }, {
        authName: '商品分类',
        children: [],
        order: 2,
        path: 'categories'
      }, {
        authName: '图片管理',
        children: [],
        order: 3,
        path: 'images'
      }, {
        authName: '视频管理',
        children: [],
        order: 4,
        path: 'videos'
      }],
      order: 2,
      path: 'goods'
    }, {
      authName: '资讯管理',
      id: 3,
      children: [{
        authName: '资讯列表',
        children: [],
        order: 1,
        path: 'news'
      }, {
        authName: '资讯分类',
        children: [],
        order: 2,
        path: 'news_category'
      }],
      order: 3,
      path: 'news'
    }, {
      authName: '技术资讯',
      id: 5,
      children: [{
        authName: '技术列表',
        children: [],
        order: 1,
        path: 'techs'
      }, {
        authName: '技术分类',
        children: [],
        order: 2,
        path: 'tech_section'
      }, {
        authName: '技术图片',
        children: [],
        order: 3,
        path: 'tech_image'
      }],
      order: 4,
      path: 'techs'
    }, {
      authName: '店铺设置',
      id: 4,
      children: [{
        authName: '首页显示',
        order: 1,
        path: 'showSets'
      }, {
        authName: '轮播图片',
        order: 2,
        path: 'adSets'
      }],
      order: 5
    }]);

    var savePath = function savePath(path) {
      sessionStorage.setItem('path', "/".concat(path));
    };

    return function (_ctx, _cache) {
      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_menu_item = _resolveComponent("el-menu-item");

      var _component_el_sub_menu = _resolveComponent("el-sub-menu");

      var _component_el_menu = _resolveComponent("el-menu");

      return _openBlock(), _createBlock(_component_el_menu, {
        "active-text-color": "#ffd04b",
        "background-color": _unref(variables).menuBg,
        class: "el-menu-vertical-demo",
        "default-active": defaultActive.value,
        "text-color": "#fff",
        router: "",
        "unique-opened": "",
        collapse: !_ctx.$store.getters.siderType
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menusList.value, function (item, index) {
            return _openBlock(), _createBlock(_component_el_sub_menu, {
              index: item.id,
              key: item.id
            }, {
              title: _withCtx(function () {
                return [_createVNode(_component_el_icon, null, {
                  default: _withCtx(function () {
                    return [(_openBlock(), _createBlock(_resolveDynamicComponent(iconList.value[index])))];
                  }),
                  _: 2
                }, 1024), _createElementVNode("span", null, _toDisplayString(item.authName), 1)];
              }),
              default: _withCtx(function () {
                return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, function (it) {
                  return _openBlock(), _createBlock(_component_el_menu_item, {
                    index: '/' + it.path,
                    key: it.id,
                    onClick: function onClick($event) {
                      return savePath(it.path);
                    }
                  }, {
                    title: _withCtx(function () {
                      return [_createVNode(_component_el_icon, null, {
                        default: _withCtx(function () {
                          return [(_openBlock(), _createBlock(_resolveDynamicComponent(icon.value)))];
                        }),
                        _: 1
                      }), _createElementVNode("span", null, _toDisplayString(_ctx.$t("menus.".concat(it.path))), 1)];
                    }),
                    _: 2
                  }, 1032, ["index", "onClick"]);
                }), 128))];
              }),
              _: 2
            }, 1032, ["index"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["background-color", "default-active", "collapse"]);
    };
  }
};