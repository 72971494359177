import request from './request'

export const newsList = (params) => {
  return request({
    url: '/AppletMallNews/newsList',
    method: 'GET',
    params
  })
}
export const newsListByCategory = (params) => {
  return request({
    url: '/AppletMallNews/newsListByCategory',
    method: 'GET',
    params
  })
}

export const updateSort = (params) => {
  return request({
    url: '/AppletMallNews/updateNewsSort',
    method: 'GET',
    params
  })
}
export const indexShowStatus = (params) => {
  console.log(params)
  return request({
    url: '/AppletMallNews/indexShowStatus',
    method: 'GET',
    params
  })
}
export const showStatus = (params) => {
  return request({
    url: '/AppletMallNews/ShowStatus',
    method: 'GET',
    params
  })
}
export const getNewsInfo = (params) => {
  return request({
    url: '/AppletMallNews/getNewsInfo',
    method: 'GET',
    params
  })
}
export const getNewsCategoryList = (params) => {
  return request({
    url: '/AppletMallNews/getAllCategory',
    method: 'GET',
    params
  })
}
export const saveCategory = (params) => {
  return request({
    url: '/AppletMallNews/saveCategory',
    method: 'GET',
    params
  })
}
export const saveNewsCategory = (params) => {
  return request({
    url: '/AppletMallNews/saveNewsCategory',
    method: 'GET',
    params
  })
}
export const editNewsCategory = (params) => {
  return request({
    url: '/AppletMallNews/EditNewsCategory',
    method: 'GET',
    params
  })
}

export const editGalaryImage = (params) => {
  return request({
    url: '/AppletMallNews/editGalaryImage',
    method: 'GET',
    params
  })
}
export const getAllSpecification = () => {
  return request({
    url: '/AppletMallNews/getAllSpecification',
    method: 'GET'
  })
}
export const getNewsSpec = (params) => {
  return request({
    url: '/AppletMallNews/getNewsSpec',
    method: 'GET',
    params
  })
}
export const storeNews = (data) => {
  return request({
    url: '/AppletMallNews/storeNews',
    method: 'POST',
    data
  })
}
