import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default {
  setup: function setup(__props) {
    var store = useStore();
    var route = useRoute();

    var onLoad = function onLoad() {
      var token = route.query.token;

      if (typeof token !== 'undefined') {
        store.dispatch('app/loginByToken', token);
      }
    };

    onLoad();
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div");
    };
  }
};