import request from './request'

export const goodsList = (params) => {
  return request({
    url: '/AppletMallGoods/goodsList',
    method: 'GET',
    params
  })
}
export const goodsListByCategory = (params) => {
  return request({
    url: '/AppletMallGoods/goodsListByCategory',
    method: 'GET',
    params
  })
}

export const updateSort = (params) => {
  return request({
    url: '/AppletMallGoods/updateGoodsSort',
    method: 'GET',
    params
  })
}
export const indexShowStatus = (params) => {
  console.log(params)
  return request({
    url: '/AppletMallGoods/indexShowStatus',
    method: 'GET',
    params
  })
}
export const saleStatus = (params) => {
  return request({
    url: '/AppletMallGoods/saleStatus',
    method: 'GET',
    params
  })
}
export const getGoodsInfo = (params) => {
  return request({
    url: '/AppletMallGoods/getGoodsInfo',
    method: 'GET',
    params
  })
}
export const getGalleryList = (params) => {
  return request({
    url: '/AppletMallGoods/getGalleryList',
    method: 'GET',
    params
  })
}
export const saveGoodsCategory = (data) => {
  return request({
    url: '/AppletMallGoods/saveGoodsCategory',
    method: 'POST',
    data: data
  })
}
export const editGoodsCategory = (params) => {
  return request({
    url: '/AppletMallGoods/EditGoodsCategory',
    method: 'GET',
    params
  })
}

export const editGalaryImage = (params) => {
  return request({
    url: '/AppletMallGoods/editGalaryImage',
    method: 'GET',
    params
  })
}
export const getAllSpecification = () => {
  return request({
    url: '/AppletMallGoods/getAllSpecification',
    method: 'GET'
  })
}
export const getGoodsSpec = (params) => {
  return request({
    url: '/AppletMallGoods/getGoodsSpec',
    method: 'GET',
    params
  })
}
export const storeGoods = (data) => {
  return request({
    url: '/AppletMallGoods/storeGoods',
    method: 'POST',
    data
  })
}
